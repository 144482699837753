import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { LoggedUserProvider } from './contexts/LoggedUser';
import { DeliveryProvider } from './contexts/Delivery';
import { ComponentProvider } from 'contexts/Component';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <LoggedUserProvider>
        <DeliveryProvider>
          <ComponentProvider>

            <App />

          </ComponentProvider>
        </DeliveryProvider>
      </LoggedUserProvider>
    </Router>
  </React.StrictMode>
);
reportWebVitals();
