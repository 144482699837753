import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'contexts/LoggedUser';
import styles from './styles.module.scss'; 

export default function UseListComponent() {
    const { loggedUser } = useContext(UserContext);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [inputPage, setInputPage] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [listChoice, setListChoice] = useState(null);
    const [componentList, setComponentList] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});

     // Stan na przechowanie statusów komponentów
     const [componentStatuses, setComponentStatuses] = useState([]);

    const filterMapping = {
        "Numer Wewnętrzny": "insideNumber",
        "Nr GL": "nameOne",
        "Nazwa": "nameTwo",
        "Typ Komponentu": "componentType.name",
        "Status": "status.name",
        "Nr dostawy": "delivery.number",
        "Stworzone przez": "createdByUser.username",
        "Data kontroli": "controlDate",
        "Data złomowania": "scrappedAt",
        "Data produkcji": "productionDate",
        "Numer MON OLD": "oldMonNumber",
        "Numer MON NEW": "newMonNumber",
        "Rozmiar": "size",
        "Magazyn": "warehouse.name",
        "Pozycja": "warehousePosition.name",
        "Numer WZ": "wzNumber",
        "Nr zamówienia": "outsideNumber",
        "Data wysyłki": "shippingDate",
    };

    const choiceClicked = (choice) => {
        setListChoice(choice);
        setCurrentPage(1);
        getComponentList(choice.id, 1, itemsPerPage);
    };

    // Pobieramy dane z API na temat statusów komponentów
    const getComponentStatuses = () => {
        axios.get(`${API_BASE_URL}/component-statuses`)
            .then((res) => {
				console.log(res.data)
                setComponentStatuses(res.data);
            })
            .catch(error => {
                console.error('Error fetching component statuses:', error);
            });
    };

    // Wywołanie pobrania statusów komponentów po zalogowaniu
    useEffect(() => {
        getListToChoice();
        getComponentStatuses();
    }, [loggedUser]);

    const buildFiltersQuery = () => {
		const filters = [];
		Object.keys(activeFilters).forEach(header => {
			const filter = activeFilters[header];
			if (filter.active && filter.value) {
				const columnName = filterMapping[header] || filterMapping[header.trim()] || header.trim();
				const filterType = filter.type || 'like'; // Używamy wybranego przez użytkownika typu filtra
	
				let valueWithTime = filter.value;
				let value2WithTime = filter.value2;
	
				if (filterType === 'lt') {
					valueWithTime = `${filter.value}T00:00:00`;
				} else if (filterType === 'gt') {
					valueWithTime = `${filter.value}T23:59:59`;
				} else if (filterType === 'between') {
					valueWithTime = `${filter.value}T00:00:00`;
					value2WithTime = `${filter.value2}T23:59:59`;
				}
	
				if (filterType === 'between' && filter.value2) {
					filters.push(`${columnName}:${filterType}|${valueWithTime}|${value2WithTime}`);
				} else {
					filters.push(`${columnName}:${filterType}|${valueWithTime}`);
				}
			}
		});
		return filters.join(';');
	};
	

    const getComponentList = (choiceId, page, limit = itemsPerPage) => {
        const filtersQuery = buildFiltersQuery();
        const url = `${API_BASE_URL}/components/newComponents?page=${page}&limit=${limit}&sort=insideNumber&order=DESC&filter=componentType.id:eq|${choiceId}`;
        const finalUrl = filtersQuery ? `${url};${filtersQuery}` : url;

        axios.get(finalUrl)
            .then((res) => {
                setComponentList(res.data.data);
                setTotalPages(res.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => {
                const newPage = prev + 1;
                getComponentList(listChoice.id, newPage, itemsPerPage);
                return newPage;
            });
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => {
                const newPage = prev - 1;
                getComponentList(listChoice.id, newPage, itemsPerPage);
                return newPage;
            });
        }
    };

    const handlePageInput = (event) => {
        setInputPage(event.target.value);
    };

    const goToPage = () => {
        const pageNumber = parseInt(inputPage, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            getComponentList(listChoice.id, pageNumber, itemsPerPage);
        }
    };

    const handleItemsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        setItemsPerPage(newLimit);
        getComponentList(listChoice.id, currentPage, newLimit);
    };

    const navigate = useNavigate();

    const redirectToComponentManagement = (idFromDeliveryList) => {
        navigate(`/componentManagement/${idFromDeliveryList}`);
    };

    const toggleFilter = (header) => {
		const isDateField = ["Data kontroli", "Data złomowania", "Data produkcji", "Data wysyłki"].includes(header);
	
		setActiveFilters(prevFilters => ({
			...prevFilters,
			[header]: {
				...prevFilters[header],
				active: !prevFilters[header]?.active,
				value: prevFilters[header]?.active ? '' : prevFilters[header]?.value || '',
				type: prevFilters[header]?.active ? prevFilters[header].type : (isDateField ? 'lt' : 'like') // Ustawienie typu na 'lt' dla daty i 'like' dla pozostałych
			}
		}));
	};

    const handleFilterChange = (header, value, isSecondDate = false) => {
		setActiveFilters(prevFilters => ({
			...prevFilters,
			[header]: {
				...prevFilters[header],
				[isSecondDate ? 'value2' : 'value']: value
			}
		}));
	};	

    const handleFilterTypeChange = (header, type) => {
        setActiveFilters(prevFilters => ({
            ...prevFilters,
            [header]: {
                ...prevFilters[header],
                type: type
            }
        }));
    };

    const closeFilter = (header) => {
        setActiveFilters(prevFilters => ({
            ...prevFilters,
            [header]: {
                active: false,
                value: '',
                value2: '', // Resetowanie drugiej wartości daty
                type: 'like' // Resetowanie typu filtra do 'like'
            }
        }));
    };

    const getFilterStyle = (header) => {
        return activeFilters[header]?.active ? styles.activeFilter : styles.inactiveFilter;
    };

    const [listToChoice, setListToChoice] = useState([]);
    const getListToChoice = () => {
        if (loggedUser) {
            axios.get(`${API_BASE_URL}/component-types`)
                .then((res) => {
                    const transformedData = res.data.map(component => ({
                        id: component.id,
                        name: component.name
                    }));
                    setListToChoice(transformedData);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    };

    useEffect(() => {
        getListToChoice();
    }, [loggedUser]);

    return {
        componentList,
        listToChoice,
        listChoice,
        choiceClicked,
        redirectToComponentManagement,
        currentPage,
        totalPages,
        handleNextPage,
        handlePrevPage,
        inputPage,
        handlePageInput,
        goToPage,
        itemsPerPage,
        handleItemsPerPageChange,
        getComponentList,
        toggleFilter,
        handleFilterChange,
        handleFilterTypeChange, // Dodane
        closeFilter,
        getFilterStyle,
        activeFilters,
        componentStatuses
    };
}
