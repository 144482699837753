import { useContext, useState } from "react";
import { UserContext } from "contexts/LoggedUser";
import axios from "axios";

export default function UseUserPanel() {
	const { loggedUser } = useContext(UserContext);
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
	// Funkcje zmiany hasła dla zalogowanego użytkownika
	const [changePasswordForm, setChangePasswordForm] = useState({
		actualPassword: '',
		newPassword: '',
		repeatNewPassword: '',
	});
	const [errorChangePassword, setErrorChangePassword] = useState(null);
	const [newPasswordPassed, setNewPasswordPassed] = useState(null);
	const handleInputChange = (e) => {
		const target = e.target;
		const name = target.name;
		const value = target.value;

		// Jeśli zmieniane pole to actualPassword, ogranicz do liter, cyfr i znaków specjalnych bez spacji
		if (name === "actualPassword" || name === "newPassword" || name === "repeatNewPassword") {
			// Zezwalaj tylko na litery, cyfry i znaki specjalne bez spacji
			const allowedChars = value.replace(/[^a-zA-Z0-9!@#$%^&*]/g, '');
			setChangePasswordForm({
				...changePasswordForm,
				[name]: allowedChars,
			});
		} else {
			setChangePasswordForm({
				...changePasswordForm,
				[name]: value,
			});
		}
		console.log(changePasswordForm);
	};

	const handleSubmitChangePassword = (e) => {
		console.log(changePasswordForm);
		setErrorChangePassword(null);
		setNewPasswordPassed(null);
		e.preventDefault();

		if (changePasswordForm.newPassword !== changePasswordForm.repeatNewPassword) {
			setErrorChangePassword("Twoje hasła różnią się!")
		} else if (changePasswordForm.actualPassword === changePasswordForm.newPassword) {
			setErrorChangePassword("Nowe hasło nie może być takie samo jak poprzednie!")
		} else {
			axios.patch(`${API_BASE_URL}/users/change-password`, {
				currentPassword: changePasswordForm.actualPassword,
				newPassword: changePasswordForm.newPassword
			})
				.then((res) => {
					console.log(res.status)
					setNewPasswordPassed('Hasło zmienione pomyślnie!')
				})
				.catch((error) => {
					console.log(error.response.data.message)
					console.log(error)
					setErrorChangePassword(error.response.data.message);
				});
		}
	}
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return {
		changePasswordForm,
		setChangePasswordForm,
		handleInputChange,
		handleSubmitChangePassword,
		errorChangePassword,
		showPassword,
		toggleShowPassword,
		newPasswordPassed
	};
}