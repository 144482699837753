import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from 'contexts/LoggedUser';
import { useNavigate } from 'react-router-dom';

export default function UseStatusesList() {
    const { loggedUser } = useContext(UserContext);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    
    const [listToChoice, setListToChoice] = useState([]);
    const [listChoice, setListChoice] = useState(null);
    const [tableConfig, setTableConfig] = useState([]);
    const [componentList, setComponentList] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [inputPage, setInputPage] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(25);

    // Stan na przechowanie statusów komponentów
    const [componentStatuses, setComponentStatuses] = useState([]);

    // Inicjalne statyczne mapowanie dla statycznych kolumn
    const [filterMapping, setFilterMapping] = useState({
        "Numer Wewnętrzny": "insideNumber",
        "Nr GL": "nameOne",
        "Nazwa": "nameTwo",
        "Status": "status.name"
    });

    // Pobieramy dane z API na temat statusów komponentów
    const getComponentStatuses = () => {
        axios.get(`${API_BASE_URL}/component-statuses`)
            .then((res) => {
				console.log(res.data)
                setComponentStatuses(res.data);
            })
            .catch(error => {
                console.error('Error fetching component statuses:', error);
            });
    };

    // Wywołanie pobrania statusów komponentów po zalogowaniu
    useEffect(() => {
        getListToChoice();
        getComponentStatuses();
    }, [loggedUser]);

    const staticConfig = [
        { name: "Numer Wewnętrzny", className: "mainTh" },
        { name: "Nr GL", className: "mainTh" },
        { name: "Nazwa", className: "mainTh" },
        { name: "Status", className: "mainTh" }
    ];

    const getListToChoice = () => {
        if (loggedUser) {
            axios.get(`${API_BASE_URL}/component-types`)
                .then((res) => {
                    const transformedData = res.data.map(component => ({
                        id: component.id,
                        name: component.name
                    }));
                    setListToChoice(transformedData);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    };

    const getTableConfig = (choice) => {
        setListChoice(choice);
        setCurrentPage(1); 
        getComponentList(choice.id, 1, itemsPerPage);

        if (loggedUser) {
			axios.get(`${API_BASE_URL}/subcomponents/${choice.id}`)
				.then((res) => {
					const dynamicConfig = res.data.map(component => ({
						name: component.name,
						className: "",
						isISS: component.isISS,        // Dodajemy informację o typie podkomponentu
						isActivity: component.isActivity // Dodajemy informację o typie podkomponentu
					}));
	
					console.log(res.data)
					setTableConfig([...staticConfig, ...dynamicConfig]);
	
					// Dodaj dynamiczne kolumny do filterMapping
					const newFilterMapping = {};
					dynamicConfig.forEach(dynamicCol => {
						newFilterMapping[dynamicCol.name] = `[subcomponent.name:like|${dynamicCol.name};subcomponentStatus.name`;
					});
	
					setFilterMapping({
						...filterMapping, 
						...newFilterMapping
					});
				})
				.catch(error => {
					console.error('Error fetching data:', error);
				});
		}
	};

    const buildFiltersQuery = () => {
        const filters = [];
        Object.keys(activeFilters).forEach(header => {
            const filter = activeFilters[header];
            if (filter.active && filter.value) {
                const columnName = filterMapping[header] || header; 
                const filterType = filter.type || 'eq'; 
                filters.push(`${columnName}:${filterType}|${filter.value}]`);
            }
        });
        return filters.join(';');
    };

    const getComponentList = (choiceId, page, limit = itemsPerPage) => {
        const filtersQuery = buildFiltersQuery();
        const url = `${API_BASE_URL}/components/ComponentsSubcomponentsStatusList?page=${page}&limit=${limit}&sort=insideNumber&order=DESC&filter=componentType.id:eq|${choiceId}`;
        const finalUrl = filtersQuery ? `${url};${filtersQuery}` : url;

        axios.get(finalUrl)
            .then((res) => {
                setComponentList(res.data.data);
                setTotalPages(res.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    // Paginacja
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => {
                const newPage = prev + 1;
                getComponentList(listChoice.id, newPage, itemsPerPage);
                return newPage;
            });
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => {
                const newPage = prev - 1;
                getComponentList(listChoice.id, newPage, itemsPerPage);
                return newPage;
            });
        }
    };

    const handlePageInput = (event) => {
        setInputPage(event.target.value);
    };

    const goToPage = () => {
        const pageNumber = parseInt(inputPage, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            getComponentList(listChoice.id, pageNumber, itemsPerPage);
        }
    };

    const handleItemsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        setItemsPerPage(newLimit);
        setCurrentPage(1);
        getComponentList(listChoice.id, 1, newLimit);
    };

    const navigate = useNavigate();
    const redirectToComponentManagement = (idFromDeliveryList) => {
        navigate(`/componentManagement/${idFromDeliveryList}`);
    };

    const toggleFilter = (header) => {
		const isStaticColumn = filterMapping[header] && !filterMapping[header].includes('subcomponentStatus.name');
	
		setActiveFilters(prevFilters => ({
			...prevFilters,
			[header]: {
				...prevFilters[header],
				active: !prevFilters[header]?.active,
				value: prevFilters[header]?.value || '',
				// Ustawiamy domyślnie like dla inputów i eq dla selectów
				type: prevFilters[header]?.type || (isStaticColumn ? 'like' : 'eq')
			}
		}));
	};

    const handleFilterChange = (header, value) => {
        setActiveFilters(prevFilters => ({
            ...prevFilters,
            [header]: {
                ...prevFilters[header],
                value: value
            }
        }));
    };

    const handleFilterTypeChange = (header, type) => {
        setActiveFilters(prevFilters => ({
            ...prevFilters,
            [header]: {
                ...prevFilters[header],
                type: type
            }
        }));
    };

    const closeFilter = (header) => {
		const isStaticColumn = filterMapping[header] && !filterMapping[header].includes('subcomponentStatus.name');
	
		setActiveFilters(prevFilters => ({
			...prevFilters,
			[header]: {
				active: false,
				value: '',
				type: isStaticColumn ? 'like' : 'eq'
			}
		}));
	};

    useEffect(() => {
        getListToChoice();
    }, [loggedUser]);

    return {
        componentList,
        listToChoice,
        listChoice,
        getTableConfig,
        tableConfig,
        redirectToComponentManagement,
        currentPage,
        totalPages,
        handleNextPage,
        handlePrevPage,
        inputPage,
        handlePageInput,
        goToPage,
        itemsPerPage,
        handleItemsPerPageChange,
        toggleFilter,
        activeFilters,
        handleFilterChange,
        handleFilterTypeChange,
        closeFilter,
		filterMapping,
		componentStatuses
    };
}
