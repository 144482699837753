import React, { useContext } from 'react';
import { FaFilter } from 'react-icons/fa';
import styles from './styles.module.scss';
import UseStatusesList from './UseStatusesList';
import AccessDenied from 'components/shared/accessDenied/AccessDenied';
import { checkUserRole } from 'utils/authUtils';
import { UserContext } from 'contexts/LoggedUser';

function StatusesList() {
    const { loggedUser } = useContext(UserContext);
    const allowedRoles = ["admin", "manager", "leader", "employee"];
    const isAuthorized = checkUserRole(loggedUser, allowedRoles);

    const {
        componentList,
        listToChoice,
        listChoice,
        tableConfig,
        getTableConfig,
        redirectToComponentManagement,
        currentPage,
        totalPages,
        handleNextPage,
        handlePrevPage,
        inputPage,
        handlePageInput,
        goToPage,
        itemsPerPage,
        handleItemsPerPageChange,
        toggleFilter,
        activeFilters,
        handleFilterChange,
        handleFilterTypeChange,
        closeFilter,
        filterMapping,
        componentStatuses
    } = UseStatusesList();

    const handleApplyFilters = () => {
        getTableConfig(listChoice); // Odświeżamy dane z filtrami
    };

    if (!isAuthorized) {
        return (<AccessDenied />);
    } else if (isAuthorized && loggedUser && listChoice === null) {
        return (
            <div className={styles.wrapper}>
                <h2>Wybierz typ materiału</h2>
                <ul className={styles.ulList}>
                    {listToChoice.map((list, index) => (
                        <button
                            key={index}
                            onClick={() => getTableConfig(list)}
                            className={styles.btn}
                        >
                            {list.name}
                        </button>
                    ))}
                </ul>
            </div>
        );
    } else if (isAuthorized && loggedUser && listChoice) {
        return (
            <div className={styles.wrapper}>
                {/* Sekcja dynamicznych filtrów nad tabelą */}
                {Object.keys(activeFilters).map((header) => {
                    const isStaticColumn = filterMapping[header] && !filterMapping[header].includes('subcomponentStatus.name');

                    // Znajdź typ podkomponentu na podstawie dynamicznej konfiguracji tabeli
                    const subcomponentType = tableConfig.find(config => config.name === header);

                    return activeFilters[header]?.active && (
                        <div key={header} className={styles.filterContainer}>
                            <label className={styles.filterLabel}>
                                {header}:
                                {/* Wyświetlamy odpowiednie selecty dla różnych typów podkomponentów */}
                                {header === "Status" ? (
                                    <select
                                        value={activeFilters[header].value}
                                        onChange={(e) => handleFilterChange(header, e.target.value)}
                                        className={styles.filterSelect}
                                    >
                                        <option value="">Wybierz status</option>
                                        {componentStatuses.map(status => (
                                            <option key={status.id} value={status.name}>
                                                {status.name}
                                            </option>
                                        ))}
                                    </select>
                                ) : header === "Numer Wewnętrzny" || header === "Nr GL" || header === "Nazwa" ? (
                                    <input
                                        type="text"
                                        placeholder="Wprowadź wartość"
                                        value={activeFilters[header].value}
                                        onChange={(e) => handleFilterChange(header, e.target.value)}
                                        className={styles.filterInput}
                                    />
                                ) : subcomponentType?.isISS ? (
                                    <select
                                        value={activeFilters[header].value}
                                        onChange={(e) => handleFilterChange(header, e.target.value)}
                                        className={styles.filterSelect}
                                    >
                                        <option value="">Wybierz opcję</option>
                                        <option value="TBC">TBC</option>
                                        <option value="N/A">N/A</option>
                                        <option value="OK">OK</option>
                                        <option value="NOK">NOK</option>
                                        <option value="WPHJ">WPHJ</option>
                                    </select>
                                ) : subcomponentType?.isActivity ? (
                                    <select
                                        value={activeFilters[header].value}
                                        onChange={(e) => handleFilterChange(header, e.target.value)}
                                        className={styles.filterSelect}
                                    >
                                        <option value="">Wybierz opcję</option>
                                        <option value="TBC">TBC</option>
                                        <option value="OK">OK</option>
                                        <option value="NOK">NOK</option>
                                        <option value="NAPR">NAPR</option>
                                    </select>
                                ) : (
                                    <select
                                        value={activeFilters[header].value}
                                        onChange={(e) => handleFilterChange(header, e.target.value)}
                                        className={styles.filterSelect}
                                    >
                                        <option value="">Wybierz opcję</option>
                                        <option value="TBC">TBC</option>
                                        <option value="OK">OK</option>
                                        <option value="NOK">NOK</option>
                                        <option value="NAPR">NAPR</option>

                                        {/* Dodatkowa opcja dla "Szklany klosz"
                                        {header === "Sz. Klosz" && (
                                            <option value="PŁSK">PŁSK</option>
                                        )} */}
                                    </select>
                                )}
                            </label>

                            {header !== "Status" && isStaticColumn && (
                                <div className={styles.inputFilterContainer}>
                                    {/* Select do zmiany między eq i like */}
                                    <select
                                        className={styles.filterTypeSelect}
                                        value={activeFilters[header].type}
                                        onChange={(e) => handleFilterTypeChange(header, e.target.value)}
                                    >
                                        <option value="like">Zawiera</option>
                                        <option value="eq">Równe</option>
                                    </select>
                                </div>
                            )}


                            {/* Przycisk zamykający filtr */}
                            <button
                                className={styles.closeFilterButton}
                                onClick={() => closeFilter(header)}
                            >
                                &times;
                            </button>
                        </div>
                    );
                })}

                <div className={styles.applyFiltersContainer}>
                    <button onClick={handleApplyFilters} className={styles.applyFiltersButton}>
                        Zastosuj filtry
                    </button>
                </div>

                <table className={styles.table}>
                    <thead className={styles.tHead}>
                        <tr className={styles.headTr}>
                            {tableConfig.map((tableHeaders, index) => (
                                <th key={index} className={`${styles.tableHeaders} ${styles[tableHeaders.className]}`}>
                                    {tableHeaders.name}
                                    <FaFilter
                                        className={`${styles.filterIcon} (tableHeaders.name) ? styles.activeFilter : styles.inactiveFilter}`}
                                        onClick={() => toggleFilter(tableHeaders.name)}
                                    />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={styles.tBody}>
                        {componentList && componentList.map(list => (
                            <tr
                                key={list.id}
                                className={styles.bodyTr}
                                onClick={() => {
                                    redirectToComponentManagement(list.id);
                                }}
                            >
                                {tableConfig.map((tableHeader, index) => {
                                    let cellContent = "";
                                    let cellClassName = "";

                                    const fieldMappings = {
                                        "Numer Wewnętrzny": "insideNumber",
                                        "Nr GL": "nameOne",
                                        "Nazwa": "nameTwo",
                                        "Status": "statusName"
                                    };

                                    if (fieldMappings[tableHeader.name]) {
                                        cellContent = list[fieldMappings[tableHeader.name]];
                                    } else {
                                        cellContent = list.componentSubcomponents.map((subcomponent, subIndex) => {
                                            if (subcomponent.componentSubcomponentName === tableHeader.name) {
                                                cellClassName =
                                                    subcomponent.subcomponentStatusName === "TBC" ? styles.tbc :
                                                        subcomponent.subcomponentStatusName === "N/A" ? styles.na :
                                                            subcomponent.subcomponentStatusName === "OK" ? styles.ok :
                                                                subcomponent.subcomponentStatusName === "WPHJ" ? styles.wphj :
                                                                    subcomponent.subcomponentStatusName === "NOK" ? styles.nok :
                                                                        subcomponent.subcomponentStatusName === "NAPR" ? styles.napr :
                                                                            "";

                                                return (
                                                    <React.Fragment key={subIndex}>
                                                        {subcomponent.subcomponentStatusName}
                                                    </React.Fragment>
                                                );
                                            }
                                            return null;
                                        });
                                    }
                                    return (
                                        <td key={index} className={cellClassName}>
                                            {cellContent}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className={styles.pagination}>
                    <button className={styles.btn} onClick={handlePrevPage} disabled={currentPage === 1}>
                        Poprzednia
                    </button>
                    <span>Strona {currentPage} z {totalPages}</span>
                    <button className={styles.btn} onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Następna
                    </button>
                </div>
                <div className={styles.pageInput}>
                    <input
                        className={styles.input}
                        type="number"
                        value={inputPage}
                        onChange={handlePageInput}
                        placeholder={"Strona"}
                        min="1"
                        max={totalPages}
                    />
                    <button className={styles.btn} onClick={goToPage}>
                        Idź do strony
                    </button>
                </div>
                <div className={styles.limitItemsPerPage}>
                    <span>Ilość na stronie: </span>
                    <select
                        className={styles.select}
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                    >
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                    </select>
                </div>
            </div>
        );
    }
}

export default StatusesList;
