export default function formatDate(date: string | Date): string {
    if (typeof date === 'string') {
        const parsedDate = new Date(Date.parse(date));
        if (isNaN(parsedDate.getTime())) {
            console.error("Nieprawidłowy format daty:", date);
            return '';
        }
        return formatDate(parsedDate);
    } else if (date instanceof Date) {
        const year = date.getFullYear();  // Użyj getFullYear dla lokalnego roku
        const month = String(date.getMonth() + 1).padStart(2, '0');  // Użyj getMonth dla lokalnego miesiąca
        const day = String(date.getDate()).padStart(2, '0');  // Użyj getDate dla lokalnego dnia
        return `${day}.${month}.${year}`;
    } else {
        return '';
    }
}