// @styles
import styles from './styles.module.scss';

function Footer() {
    return (
        <div className={styles.wrapper}>
           <div className={styles.version}>v.0.06012024.01.01</div>
        </div>
    )
}
export default Footer;