import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import formatDate from 'utils/dateUtils';
import styles from './styles.module.scss';

const ExportToExcelButton = ({ componentTypeId }) => {

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [loading, setLoading] = useState(false);

  const fetchDataAndExportToExcel = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${API_BASE_URL}/components/componentsToExport/?componentTypeId=${componentTypeId}`);
      const data = response.data;

      // Mapa do przetłumaczenia nazw pól na nazwy kolumn
      const fieldToColumnNameMap = {
        insideNumber: 'Numer Wewnętrzny',
        nameOne: 'Nr GL',
        nameTwo: 'Nazwa',
        statusName: 'Status',
        deliveryNumber: 'Numer Dostawy',
        createdByUser: 'Utworzony Przez',
        controlDate: 'Data Kontroli',
        scrapedAt: 'Data Złomowania',
        productionDate: 'Data Produkcji',
        numberMonOld: 'Stary Numer MON',
        numberMonNew: 'Nowy Numer MON',
        size: 'Rozmiar',
        warehouseName: 'Magazyn',
        positionName: 'Pozycja',
        shippingNumber: 'Numer Wysyłki',
        shippingDate: 'Data Wysyłki',
        creationDate: 'Data Utworzenia',
        componentTypeName: 'Typ Komponentu'
      };

      // Przykładowe przetwarzanie danych do formatu akceptowalnego przez Excel
      const formattedData = data.map(item => {
        const subcomponents = item.componentSubcomponents.reduce((acc, subcomponent) => {
          acc[subcomponent.subcomponentName] = subcomponent.subcomponentStatusName;
          return acc;
        }, {});

        const formattedItem = {
          ...Object.keys(fieldToColumnNameMap).reduce((acc, key) => {
            const value = item[key];
            if (['controlDate', 'scrapedAt', 'productionDate', 'shippingDate', 'creationDate'].includes(key)) {
              acc[fieldToColumnNameMap[key]] = formatDate(value);
            } else {
              acc[fieldToColumnNameMap[key]] = value;
            }
            return acc;
          }, {}),
          ...subcomponents
        };
        return formattedItem;
      });

      // Konwersja danych do tablicy Excelowej
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Ustawienie auto-filtra
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) };

      // Tworzenie nowej książki
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

      // Zapisanie do pliku Excel
      XLSX.writeFile(workbook, 'export.xlsx');
    } catch (error) {
      console.error('Błąd podczas pobierania danych:', error);
    }

    setLoading(false);
  };

  return (
    <div className={styles.btnWrapper}>
      <button className={styles.btn} onClick={fetchDataAndExportToExcel} disabled={loading}>
        {loading ? 'Wczytywanie...' : 'Export do Excel'}
      </button>
    </div>
  );
};

export default ExportToExcelButton;