export const componentsTable = {
    details: {
        1: [
            "Numer Wewnętrzny",
            "Nr GL",
            "Nazwa",
            "Status",
            "Nr dostawy",
            "Stworzone przez",
            "Data kontroli",
            "Data złomowania",
            "Data produkcji",
            "Numer MON OLD",
            "Numer MON NEW",
            "Rozmiar",
            "Magazyn",
            "Pozycja",
            "Numer WZ",
            "Nr zamówienia",
            "Data wysyłki",
        ],
        2: [
            "Numer Wewnętrzny",
            "Nr GL",
            "Nazwa",
            "Status",
            "Nr dostawy",
            "Stworzone przez",
            "Data kontroli",
            "Data złomowania",
            "Data produkcji",
            "Numer MON OLD",
            "Numer MON NEW",
            "Magazyn",
            "Pozycja",
            "Numer WZ",
            "Nr zamówienia",
            "Data wysyłki",
        ],
    },
};
