import React, { useContext } from 'react';
import { FaFilter } from 'react-icons/fa';
import styles from './styles.module.scss';
import UseListComponent from './UseListComponent';
import AccessDenied from 'components/shared/accessDenied/AccessDenied';
import { componentsTable } from 'lib/data/DataListComponent';
import formatDate from 'utils/dateUtils';
import { checkUserRole } from 'utils/authUtils';
import ExportToExcelButton from 'components/shared/exportComponentsToExel/exportComponentsToExel';
import { UserContext } from 'contexts/LoggedUser';

function ListComponent() {
    const {
        componentList,
        listToChoice,
        listChoice,
        choiceClicked,
        redirectToComponentManagement,
        currentPage,
        totalPages,
        handleNextPage,
        handlePrevPage,
        inputPage,
        handlePageInput,
        goToPage,
        itemsPerPage,
        handleItemsPerPageChange,
        toggleFilter,
        handleFilterChange,
        closeFilter,
        getFilterStyle,
        activeFilters,
        getComponentList,
        handleFilterTypeChange, // Dodane
        componentStatuses
    } = UseListComponent();

    const { loggedUser } = useContext(UserContext);
    const allowedRoles = ["admin", "manager", "leader", "employee"];
    const isAuthorized = checkUserRole(loggedUser, allowedRoles);

    const handleApplyFilters = () => {
        getComponentList(listChoice.id, currentPage, itemsPerPage);
    };

    const isDateField = (header) => {
        const dateFields = ["Data kontroli", "Data złomowania", "Data produkcji", "Data wysyłki"];
        return dateFields.includes(header);
    };

    if (!isAuthorized) {
        return (<AccessDenied />);
    } else if (isAuthorized && loggedUser && listToChoice && listChoice === null) {
        return (
            <div className={styles.wrapper}>
                <h2>Wybierz typ materiału</h2>
                <ul className={styles.ulList}>
                    {listToChoice.map((list, index) => (
                        <button
                            key={index}
                            onClick={() => choiceClicked(list)}
                            className={styles.btn}
                        >
                            {list.name}
                        </button>
                    ))}
                </ul>
            </div>
        );
    } else if (isAuthorized && loggedUser && listChoice) {
        const tableDetailsConfig = componentsTable.details;
        return (
            <div className={styles.wrapper}>
                {/* Sekcja dynamicznych filtrów nad tabelą */}
                {Object.keys(activeFilters).map((header) => (
                    activeFilters[header]?.active && (
                        <div key={header} className={styles.filterContainer}>
                            <label className={styles.filterLabel}>
                                {header}:

                                {/* Sprawdzenie, czy header to Status */}
                                {header === "Status" ? (
                                    <select
                                        value={activeFilters[header].value}
                                        onChange={(e) => handleFilterChange(header, e.target.value)}
                                        className={styles.filterInput} // Używamy stylu filterInput
                                    >
                                        <option value="">Wybierz status</option>
                                        {componentStatuses.map(status => (
                                            <option key={status.id} value={status.name}>
                                                {status.name}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <>
                                        {/* Domyślne pole tekstowe lub daty */}
                                        <input
                                            type={isDateField(header) ? "date" : "text"}
                                            value={activeFilters[header].value}
                                            onChange={(e) => handleFilterChange(header, e.target.value)}
                                            className={styles.filterInput}
                                        />

                                        {/* Select dla opcji filtra */}
                                        <select
                                            className={styles.filterTypeSelect}
                                            value={activeFilters[header].type}
                                            onChange={(e) => handleFilterTypeChange(header, e.target.value)}
                                        >
                                            {!isDateField(header) && (
                                                <>
                                                    <option value="eq">Równe</option>
                                                    <option value="like">Zawiera</option>
                                                </>
                                            )}
                                            {isDateField(header) && (
                                                <>
                                                    <option value="lt">Mniejsze</option>
                                                    <option value="gt">Większe</option>
                                                    <option value="between">Pomiędzy</option>
                                                </>
                                            )}
                                        </select>

                                        {/* Drugie pole daty dla filtra between */}
                                        {activeFilters[header].type === 'between' && isDateField(header) && (
                                            <input
                                                type="date"
                                                value={activeFilters[header].value2 || ''}
                                                onChange={(e) => handleFilterChange(header, e.target.value, true)}
                                                className={styles.filterInput}
                                                placeholder="Data 2"
                                            />
                                        )}
                                    </>
                                )}
                            </label>

                            {/* Przycisk zamknięcia filtra */}
                            <button
                                className={styles.closeFilterButton}
                                onClick={() => closeFilter(header)}
                            >
                                &times;
                            </button>
                        </div>
                    )
                ))}

                {/* Przycisk do zastosowania filtrów */}
                <div className={styles.applyFiltersContainer}>
                    <button onClick={handleApplyFilters} className={styles.applyFiltersButton}>
                        Zastosuj filtry
                    </button>
                </div>

                {/* Sekcja tabeli */}
                <table className={styles.table}>
                    <thead className={styles.tHead}>
                        <tr className={styles.headTr}>
                            {(listChoice.id === 1 ? tableDetailsConfig[1] : tableDetailsConfig[2]).map((tableHeaders, index) => (
                                <th key={index} className={styles.th}>
                                    {tableHeaders}
                                    <FaFilter
                                        className={`${styles.filterIcon} ${getFilterStyle(tableHeaders)}`}
                                        onClick={() => toggleFilter(tableHeaders)}
                                    />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={styles.tBody}>
                        {componentList && componentList.map(list => (
                            <tr
                                key={list.id}
                                className={styles.bodyTr}
                                onClick={() => {
                                    redirectToComponentManagement(list.id)
                                }}
                            >
                                {(listChoice.id === 1 ? tableDetailsConfig[1] : tableDetailsConfig[2]).map((header, index) => (
                                    <td key={index}>
                                        {header === "Numer Wewnętrzny" && list.insideNumber}
                                        {header === "Nr GL" && list.nameOne}
                                        {header === "Nazwa" && list.nameTwo}
                                        {header === "Typ Komponentu" && list.componentTypeName}
                                        {header === "Status" && list.statusName}
                                        {header === "Nr dostawy" && list.deliveryNumber}
                                        {header === "Stworzone przez" && list.createdByUsername}
                                        {header === "Data kontroli" && formatDate(list.controlDate)}
                                        {header === "Data złomowania" && formatDate(list.scrappedAt)}
                                        {header === "Data produkcji" && formatDate(list.productionDate)}
                                        {header === "Numer MON OLD" && list.oldMonNumber}
                                        {header === "Numer MON NEW" && list.newMonNumber}
                                        {header === "Rozmiar" && list.size}
                                        {header === "Magazyn" && list.warehouseName}
                                        {header === "Pozycja" && list.warehousePositionName}
                                        {header === "Numer WZ" && list.wzNumber}
                                        {header === "Nr zamówienia" && list.outsideNumber}
                                        {header === "Data wysyłki" && formatDate(list.shippingDate)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <ExportToExcelButton componentTypeId={listChoice.id} />
                <div className={styles.pagination}>
                    <button className={styles.btn} onClick={handlePrevPage} disabled={currentPage === 1}>
                        Poprzednia
                    </button>
                    <span>Strona {currentPage} z {totalPages}</span>
                    <button className={styles.btn} onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Następna
                    </button>
                </div>
                <div className={styles.pageInput}>
                    <input
                        className={styles.input}
                        type="number"
                        value={inputPage}
                        onChange={handlePageInput}
                        placeholder={"Strona"}
                        min="1"
                        max={totalPages}
                    />
                    <button className={styles.btn} onClick={goToPage}>
                        Idź do strony
                    </button>
                    <div className={styles.limitItemsPerPage}>
                        <span>Ilość na stronie: </span>
                        <select
                            className={styles.select}
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                        >
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={25}>25</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListComponent;
