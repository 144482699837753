import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState } from 'react';
import { UserContext } from '../../../contexts/LoggedUser';

// @styles
import styles from './styles.module.scss';

//@assets
import hjOutsoursingNav from '../../../assets/logo/hjOutsourcingNav.png';

//@menu
import { listMainMenu } from "lib/data/DataMainMenuList"; // Upewnij się, że ścieżka do pliku z listą menu jest poprawna

function NavBar() {
    const navigate = useNavigate();
    const { loggedUser, logout } = useContext(UserContext);
    const [menuVisible, setMenuVisible] = useState(false);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const getAccessibleMenuItems = () => {
        if (!loggedUser) return [];
        return listMainMenu.filter(item => 
            item.visible && loggedUser.roles.some(role => item.roles.includes(role.name))
        );
    };

    const menuItems = getAccessibleMenuItems();

    const showMenu = () => {
        setMenuVisible(true);
    };

    const hideMenu = () => {
        setMenuVisible(false);
    };

    return (
        <div className={styles.wrapper}>
            <Link to='/'><img className={styles.hjOutsoursing} src={hjOutsoursingNav} alt="HJOutsourcing" /></Link>
            {loggedUser && <div>{loggedUser.username}</div>}
            {loggedUser && <div className={styles.btnSegment}>
                <div 
                    className={styles.dropdown} 
                    onMouseEnter={showMenu} 
                    onMouseLeave={hideMenu}
                >
                    <button className={styles.btn}>Menu</button>
                    <div className={`${styles.dropdownContent} ${menuVisible ? styles.visible : ''}`}>
                        <Link to="/mainmenu" className={styles.aBtn}>Menu Główne</Link>
                        {menuItems.length > 0 ? (
                            menuItems.map((item, index) => (
                                <Link key={index} to={item.destinationURL}>{item.label}</Link>
                            ))
                        ) : (
                            <div>No menu items available</div>
                        )}
                    </div>
                </div>
                <button className={styles.btn} onClick={handleLogout}>Wyloguj</button>
            </div>}
        </div>
    )
}

export default NavBar;
