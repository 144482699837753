import React from "react";

const LoadingComponent: React.FC = () => {


    return (
        <div>Loading...</div>
    )

}
export default LoadingComponent;