import React, { useContext } from 'react';
import { UserContext } from "../../contexts/LoggedUser";

//@Styles
import './styles.module.scss';

//@components
import AccessDenied from 'components/shared/accessDenied/AccessDenied';
import DeliveryListComponent from './insidecomponent/DeliveryListComponent';

// @utils
import { checkUserRole } from '../../utils/authUtils';

function ListOrder() {
    //Zabezpieczenie strony
    const { loggedUser } = useContext(UserContext);
    const allowedRoles = ["admin", "manager", "leader", "employee"];
    const isAuthorized = checkUserRole(loggedUser, allowedRoles);

    if (!isAuthorized) {
        return (<AccessDenied />);
    }
    else if (isAuthorized && loggedUser) {
        return (
            <DeliveryListComponent />
        )
    }
}
export default ListOrder;