import React, { useContext } from 'react';
import { UserContext } from "../../contexts/LoggedUser";

// @components
import AccessDenied from 'components/shared/accessDenied/AccessDenied';
import LoadingComponent from 'components/shared/loadingcomponent/LoadingComponent';

//@Hooks
import UseUserPanel from './UseUserPanel';

// @styles
import styles from './styles.module.scss';

// @utils
import { checkUserRole } from '../../utils/authUtils';

function UserPanel() {
    //Zabezpieczenie strony
    const { loggedUser } = useContext(UserContext);
    const allowedRoles = ["admin", "manager", "leader", "employee"];
    const isAuthorized = checkUserRole(loggedUser, allowedRoles);
    const {
        changePasswordForm,
        handleInputChange,
        handleSubmitChangePassword,
        errorChangePassword,
        showPassword,
        toggleShowPassword,
        newPasswordPassed
    } = UseUserPanel();

    if (!isAuthorized) {
        return (<AccessDenied />);
    } else if (isAuthorized && loggedUser) {
        return (
            <div className={styles.wrapper}>
                <form
                    className={styles.changePassword}
                    onSubmit={handleSubmitChangePassword}
                    method="POST"
                >
                    <div className={styles.formHeader}>
                        <h3>Zmień hasło</h3>
                    </div>
                    <div className={styles.formContent}>
                        <label htmlFor="actualPassword">Aktualne Hasło: </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="actualPassword"
                            name="actualPassword"
                            value={changePasswordForm.actualPassword}
                            onChange={handleInputChange}
                            title="Dozwolone znaki: litery (a-z, A-Z), cyfry (0-9), znaki specjalne (!@#$%^&*)"
                            autoComplete="off"
                            required
                        />
                        <label htmlFor="newPassword">Nowe Hasło: </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="newPassword"
                            name="newPassword"
                            value={changePasswordForm.newPassword}
                            onChange={handleInputChange}
                            title="Dozwolone znaki: litery (a-z, A-Z), cyfry (0-9), znaki specjalne (!@#$%^&*)"
                            autoComplete="off"
                            required
                        />
                        <label htmlFor="repeatNewPassword">Powtórz Hasło: </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="repeatNewPassword"
                            name="repeatNewPassword"
                            value={changePasswordForm.repeatNewPassword}
                            onChange={handleInputChange}
                            title="Dozwolone znaki: litery (a-z, A-Z), cyfry (0-9), znaki specjalne (!@#$%^&*)"
                            autoComplete="off"
                            required
                        />
                        <button
                            type="button"
                            onClick={toggleShowPassword}
                            className={styles.btn}
                        >
                            {showPassword ? "Ukryj hasła" : "Pokaż hasła"}
                        </button>
                        {errorChangePassword && <div className={styles.error}>{errorChangePassword}</div>}
                        {newPasswordPassed && <div className={styles.succes}>{newPasswordPassed}</div>}
                        <input
                            className={styles.btn}
                            type="submit"
                            value="Zmień hasło"
                        />
                    </div>
                </form>
            </div>
        );
    } else {
        return (<LoadingComponent />)
    }
}
export default UserPanel;