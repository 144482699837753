import React, { createContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode'; // Biblioteka do dekodowania tokenów JWT
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Tworzenie kontekstu
export const UserContext = createContext();

// Zmienne środowiskowe
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Tworzenie dostawcy kontekstu
export const LoggedUserProvider = ({ children }) => {
  const [token, setToken] = useState(sessionStorage.getItem('token')); // Access token
  const [loggedUser, setLoggedUser] = useState();
  const navigate = useNavigate();

  const logout = () => {
    setLoggedUser(null);
    sessionStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];

    try {
      const response = axios.post(
        `${API_BASE_URL}/auth/logout`, // Endpoint
        {}, // Body (pusty, ponieważ dane są w ciasteczku)
        {
          withCredentials: true, // Ustawienie, aby ciasteczka były wysyłane
        }
      );
      console.log(response)

    } catch (error) {
      console.error('Błąd podczas zapytania do auth/refresh:', error);
    }
  };

  const login = (userData) => {
    setToken(userData.tokenJWT);
    sessionStorage.setItem('token', userData.tokenJWT);
    axios.defaults.headers.common['Authorization'] = `Bearer ${userData.tokenJWT}`;
  };

  const setLoggedInUserFromToken = () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setLoggedUser({
          userId: decodedToken.id,
          username: decodedToken.username,
          roles: decodedToken.roles,
          // userId: decodedToken.cleanPayload.id,
          // username: decodedToken.cleanPayload.username,
          // roles: decodedToken.cleanPayload.roles,
        });
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } catch (error) {
        sessionStorage.removeItem('token');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    setLoggedInUserFromToken();
  }, []);


  let isRefreshing = false;
  let subscribers = [];
  // Funkcja do wykonania zapytania do auth/refresh i wyświetlenia wyniku w konsoli
  const logRefreshTokenResponse = () => {
    isRefreshing = true;
    delete axios.defaults.headers.common['Authorization'];

    return axios.post(
      `${API_BASE_URL}/auth/refresh`,
      {},
      { withCredentials: true }
    )
      .then((response) => {
        console.log('Otrzymano odpowiedź z auth/refresh:', response.data);
        const newToken = response.data.access_token;
        sessionStorage.setItem('token', newToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

        // Powiadom wszystkie subskrybentów, że token jest odświeżony
        subscribers.forEach((callback) => callback(newToken));
        subscribers = [];

        return newToken;
      })
      .catch((error) => {
        console.error('Błąd podczas odświeżania tokena:', error.response);
        return null;
      })
      .finally(() => {
        isRefreshing = false;
      });
  };

  // Funkcja subskrypcji zapytań, które czekają na nowy token
  const subscribeTokenRefresh = (callback) => {
    subscribers.push(callback);
  };

  // Użycie `useEffect`, aby dodać interceptor, gdy komponent się zamontuje
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response, // Zwraca poprawną odpowiedź
      (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          if (isRefreshing) {
            // Jeśli token jest już odświeżany, dodaj zapytanie do subskrybentów
            return new Promise((resolve) => {
              subscribeTokenRefresh((newToken) => {
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                resolve(axios(originalRequest));
              });
            });
          }

          // Jeśli token nie jest odświeżany, wywołaj funkcję odświeżania tokena
          return logRefreshTokenResponse().then((newToken) => {
            if (newToken) {
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
              return axios(originalRequest);
            }
            return Promise.reject(error);
          });
        }

        return Promise.reject(error);
      }
    );

    // Usunięcie interceptora po odmontowaniu komponentu
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  return (
    <UserContext.Provider value={{ loggedUser, login, logout, setLoggedInUserFromToken }}>
      {children}
    </UserContext.Provider>
  );
};
