import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "contexts/LoggedUser";

export default function UseComponentManagement() {
	const { loggedUser } = useContext(UserContext);
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
	//Pobieranie danych z komponentu
	const componentId = window.location.pathname.split('/').pop();
	const [componentData, setComponentData] = useState([]);
	const [subcomponentData, setSubcomponentData] = useState([]);
	const [componentTypeId, setComponentTypeId] = useState(0);
	const [isEditSubcomponentsMode, setEditSubcomponentsMode] = useState(false);
	const [isEditIssMode, setEditIssMode] = useState(false);
	const [isEditActivitiesMode, setEditActivitiesMode] = useState(false);

	const getComponentData = () => {
		axios.get(`${API_BASE_URL}/components/${componentId}`)
			.then((res) => {
				setComponentData([res.data]);
				console.log(res)
				const sortedSubcomponents = res.data.componentSubcomponents.sort((a, b) => a.id - b.id);
				console.log(sortedSubcomponents);
				setSubcomponentData(sortedSubcomponents);
				setComponentTypeId(res.data.componentType.id)
				setFormData({
					nameOne: res.data.nameOne,
					nameTwo: res.data.nameTwo,
					controlDate: res.data.controlDate,
					productionDate: res.data.productionDate,
					newMonNumber: res.data.newMonNumber,
					oldMonNumber: res.data.oldMonNumber,
					size: res.data.size,
					scrappedAt: res.data.scrappedAt,
					outsideNumber: res.data.outsideNumber,
					shippingDate: res.data.shippingDate,
					wzNumber: res.data.wzNumber,
				});
				console.log(res.data.componentType.name);
			})
			.catch(() => {
				//DODAJ OBSŁUGE BŁĘDÓW
			})
	}
	const changeSubcomponentStatus = (subcomponentId, statusId) => {
		console.log(subcomponentId)
		console.log(statusId)

		if (statusId !== 9999) {
			axios
				.put(`${API_BASE_URL}/component-subcomponents/${subcomponentId}`, {
					statusId: statusId,
				}).then((res) => {
					console.log(res);
					getComponentData();
				})
		}
	}
	const [scrapForm, setScrapForm] = useState({
		scrappedAt: '',
		outsideNumber: '',
		shippingDate: '',
		wzNumber: ''
	})
	const handleScrapComponentChange = (e) => {
		const target = e.target;
		const name = target.name;
		console.log(scrapForm);
		setScrapForm({
			...scrapForm,
			[name]: target.value
		});
	}
	const handleScrapSubbmit = () => {
		axios
			.put(`${API_BASE_URL}/components/${componentId}`, {
				scrappedAt: scrapForm.scrappedAt
			}).then((res) => {
				console.log(res);
				getComponentData();
			})
	}
	const handleOrderSubmit = () => {
		axios
			.put(`${API_BASE_URL}/components/${componentId}`, {
				outsideNumber: scrapForm.outsideNumber
			})
			.then((res) => {
				console.log(res);
				getComponentData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};
	const handleDataOrderSubmit = () => {
		// Formatowanie daty do formatu ISO 8601
		const formattedShippingDate = new Date(scrapForm.shippingDate).toISOString();

		axios
			.put(`${API_BASE_URL}/components/${componentId}`, {
				shippingDate: formattedShippingDate
			})
			.then((res) => {
				console.log(res);
				getComponentData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	const handleWzNumberSubmit = () => {
		axios
			.put(`${API_BASE_URL}/components/${componentId}`, {
				wzNumber: scrapForm.wzNumber
			})
			.then((res) => {
				console.log(res);
				getComponentData();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};
	const [tableConfig, setTableConfig] = useState();

	const getTableConfig = () => {

		if (loggedUser && componentTypeId) {
			axios.get(`${API_BASE_URL}/subcomponents/${componentTypeId}`)
				.then((res) => {
					const data = res.data;

					const dynamicConfig = {
						activities: [],
						iss: [],
						subcomponents: []
					};

					data.forEach(item => {
						if (item.isISS) {
							dynamicConfig.iss.push({ name: item.name });
						} else if (item.isActivity) {
							dynamicConfig.activities.push({ name: item.name });
						} else {
							dynamicConfig.subcomponents.push({ name: item.name });
						}
					});
					setTableConfig(dynamicConfig);
					console.log(dynamicConfig);
				})
				.catch(error => {
					console.error('Error fetching data:', error);
				});
		}
	};

	const [formData, setFormData] = useState({
		nameOne: null,
		nameTwo: null,
		controlDate: null,
		productionDate: null,
		newMonNumber: null,
		oldMonNumber: null,
		size: null,
		scrappedAt: null,
		shippingDate: null,
		outsideNumber: null,
		wzNumber: null,
	});

	const handleFormChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const [errorCorrectComponent, setErrorCorrectComponent] = useState()
	const handleFormSubmit = (e) => {
		e.preventDefault(); // Powstrzymuje przedładowanie strony
	
		// Funkcja pomocnicza do zamiany pustych ciągów znaków na null
		const sanitizeValue = (value) => {
			return value === "" ? null : value;
		};
	
		const formattedControlDate = sanitizeValue(formData.controlDate ? new Date(formData.controlDate).toISOString() : null);
		const formattedProductionDate = sanitizeValue(formData.productionDate ? new Date(formData.productionDate).toISOString() : null);
		const formattedScrappedAt = sanitizeValue(formData.scrappedAt ? new Date(formData.scrappedAt).toISOString() : null);
		const formattedShippingDate = sanitizeValue(formData.shippingDate ? new Date(formData.shippingDate).toISOString() : null);
	
		axios
			.put(`${API_BASE_URL}/components/${componentId}`, {
				nameOne: sanitizeValue(formData.nameOne),
				nameTwo: sanitizeValue(formData.nameTwo),
				controlDate: formattedControlDate,
				productionDate: formattedProductionDate,
				newMonNumber: sanitizeValue(formData.newMonNumber),
				oldMonNumber: sanitizeValue(formData.oldMonNumber),
				size: sanitizeValue(parseFloat(formData.size)),
				scrappedAt: formattedScrappedAt,
				shippingDate: formattedShippingDate,
				outsideNumber: sanitizeValue(formData.outsideNumber),
				wzNumber: sanitizeValue(formData.wzNumber),

			})
			.then((res) => {
				setErrorCorrectComponent(null);
				getComponentData();
			})
			.catch((error) => {
				setErrorCorrectComponent(error.response.data.message);
				console.error('Error updating component data:', error.response.data.message);
			});
	};
	const [subcomponentConfig, setSubcomponentConfig] = useState([])

	const getSubcomponentConfig = () => {

		if (loggedUser && componentTypeId) {
			axios.get(`${API_BASE_URL}/subcomponents/config-by-component-type/${componentTypeId}`)
				.then((res) => {
					console.log(res.data);
                    setSubcomponentConfig(res.data)
				})
				.catch(error => {
					console.error('Error fetching data:', error);
				});
		}
	};

    useEffect(() => {
        if (loggedUser && componentTypeId) {
            getSubcomponentConfig();
        }
    }, [loggedUser, componentTypeId]);
	


	useEffect(() => {
		if (loggedUser && componentTypeId) {
			getTableConfig();
		}
	}, [loggedUser, componentTypeId])

	useEffect(() => {
		if (loggedUser) {
			getComponentData();
		}
	}, [loggedUser])

	return {
		tableConfig,
		componentData,
		subcomponentData,
		componentTypeId,
		changeSubcomponentStatus,
		scrapForm,
		handleScrapSubbmit,
		handleScrapComponentChange,
		handleOrderSubmit,
		handleDataOrderSubmit,
		handleWzNumberSubmit,
		isEditSubcomponentsMode,
		setEditSubcomponentsMode,
		isEditIssMode,
		setEditIssMode,
		isEditActivitiesMode,
		setEditActivitiesMode,
		formData,
		handleFormChange,
		handleFormSubmit,
		errorCorrectComponent,
		subcomponentConfig,
	};
}