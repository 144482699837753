import React, { useContext, useState } from 'react';
import { DeliveryContext } from 'contexts/Delivery';

// @styles
import styles from './styles.module.scss';

//@hooks
import UseComponentListFromDeliveryComponent from './UseComponentListFromDeliveryComponent';
import formatDate from 'utils/dateUtils';

const componentFormFields = [
    { label: 'Data Produkcji:', name: 'productionDate', type: 'date', required: true },
    { label: 'Data Kontroli:', name: 'controlDate', type: 'date', required: true },
    { label: 'Nr. ,,Gl":', name: 'nameOne', type: 'text', minLength: 13, maxLength: 13, pattern: '[^\\s]+', required: true },
    { label: 'Nazwa:', name: 'nameTwo', type: 'text' },
    { label: 'Nr mon OLD:', name: 'oldMonNumber', type: 'text', minLength: 6, maxLength: 6, pattern: '[^\\s]+' },
    { label: 'Nr mon NEW:', name: 'newMonNumber', type: 'text', minLength: 9, maxLength: 9, pattern: '[^\\s]+', required: true },
];

export default function DeliveryListComponent() {
    const {
        deliveryData,
        formDataComponentAdd,
        handleInputAddComponentChange,
        handleSubmitAddComponent,
        componentFromDelivery,
        subComponentsStatus,
        handleStatusSubcomponentChange,
        subcomponentConfig
    } = UseComponentListFromDeliveryComponent();
    const { addComponentForm, setAddComponentForm, addSubcomponentsForm, setAddSubcomponentsForm } = useContext(DeliveryContext);

    const renderFormFields = (fields) => fields.map(field => (
        <div key={field.name} className={styles.formField}>
            <label htmlFor={field.name}>{field.label}</label>
            <input
                type={field.type}
                id={field.name}
                name={field.name}
                value={formDataComponentAdd[field.name]}
                onChange={handleInputAddComponentChange}
                autoComplete="off"
                required={field.required}
                minLength={field.minLength}
                maxLength={field.maxLength}
                pattern={field.pattern}
            />
        </div>
    ));

    const renderForm = (componentType) => (
        <div className={styles.addComponentForm}>
            <h3>Dodaj Komponent</h3>
            <form className={styles.formBox} onSubmit={handleSubmitAddComponent} method="POST">
                {renderFormFields(componentFormFields)}
                {componentType === 'PÓŁKI WISZĄCE - CANRAY' && (
                    <div className={styles.formField}>
                        <label htmlFor="size">Stan Magazynu:</label>
                        <select
                            id="size"
                            name="size"
                            value={formDataComponentAdd.size}
                            onChange={handleInputAddComponentChange}
                            required
                        >
                            <option></option>
                            <option value="0.3">0.3</option>
                            <option value="1.25">1.25</option>
                        </select>
                    </div>
                )}
                <div className={styles.formButtons}>
                    <input className={styles.btn} type='submit' value='Dodaj' />
                    <button type="button" onClick={() => setAddComponentForm(false)} className={styles.btnSecondary}>Zamknij</button>
                </div>
            </form>
        </div>
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                {Array.isArray(deliveryData) && deliveryData.map(deliveryItem => (
                    <div key={deliveryItem.id} className={styles.deliveryInfo}>
                        <div>Numer: {deliveryItem.number}</div>
                        <div>Status: {deliveryItem.status.name}</div>
                        <div>Typ dostawy: {deliveryItem.componentType.name}</div>
                        <div>Utworzone przez: {deliveryItem.createdByUser.username}</div>
                        <div>Klient: {deliveryItem.customer.name}</div>
                        <div>Data dodania: {formatDate(deliveryItem.creationDate)}</div>
                        <div>Data zmiany: {formatDate(deliveryItem.lastModified)}</div>
                        <div>Data dostawy: {formatDate(deliveryItem.deliveryDate)}</div>
                    </div>
                ))}
                <div className={styles.buttons}>
                    <button onClick={() => setAddComponentForm(true)} className={styles.btn}>Dodaj Komponent</button>
                </div>
                {addComponentForm && renderForm(deliveryData[0].componentType.name)}
                {addSubcomponentsForm &&
                    <div className={styles.selectStatus}>
                        <h3>Ustaw Status</h3>
                        <form className={styles.setStatusForm} onSubmit={() => setAddSubcomponentsForm(false)}>

                            <div className={styles.statusSelect}>
                                {subComponentsStatus.map(element => (
                                    <div key={element.id}>
                                        <label>{element.name}</label>
                                        <select onChange={(e) => handleStatusSubcomponentChange(e.target.value, element.id)}>
                                            <option></option>
                                            {subcomponentConfig.map(option =>
                                                option.subcomponentName === element.name
                                                    ? option.primaryStatuses.map((status, index) => (
                                                        <option key={index} value={status.id}>{status.name}</option>
                                                    ))
                                                    : null
                                            )}
                                        </select>
                                    </div>
                                ))}
                            </div>

                            <div className={styles.formButtons}>
                                <input className={styles.btn} type='submit' value='Ustaw Status' />
                            </div>
                        </form>
                    </div>}
            </div>

            <table className={styles.table}>
                <thead className={styles.tHead}>
                    <tr className={styles.headTr}>
                        <th>Numer Wewnetrzny</th>
                        <th>Nr ,,GL"</th>
                        <th>Nazwa</th>
                        <th>Typ Komponentu</th>
                        <th>Dodane Przez</th>
                        <th>Data Produkcji</th>
                        <th>Data Kontroli</th>
                        <th>Status</th>
                        <th>Magazyn</th>
                        <th>Pozycja</th>
                        <th>Numer mon OLD</th>
                        <th>Numer mon NEW</th>
                        <th>Rozmiar</th>
                    </tr>
                </thead>
                <tbody className={styles.tBody}>
                    {componentFromDelivery && componentFromDelivery.map(componentItem => (
                        <tr key={componentItem.id} className={styles.bodyTr}>
                            <td>{componentItem.insideNumber}</td>
                            <td>{componentItem.nameOne}</td>
                            <td>{componentItem.nameTwo}</td>
                            <td>{componentItem.componentType.name}</td>
                            <td>{componentItem.createdByUser.username}</td>
                            <td>{formatDate(componentItem.productionDate)}</td>
                            <td>{formatDate(componentItem.controlDate)}</td>
                            <td>{componentItem.status.name}</td>
                            <td>{componentItem.warehouse.name}</td>
                            <td>{componentItem.warehousePosition.name}</td>
                            <td>{componentItem.oldMonNumber}</td>
                            <td>{componentItem.newMonNumber}</td>
                            <td>{componentItem.size}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
