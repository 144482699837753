import React, { createContext, useEffect, useState } from 'react';

// Tworzenie kontekstu
export const ComponentContext = createContext();

// Tworzenie dostawcy kontekstu
export const ComponentProvider = ({ children }) => {



  return (
    <ComponentContext.Provider value={{  }}>
      {children}
    </ComponentContext.Provider>
  );
};
