import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from "../../contexts/LoggedUser";

// @components
import AccessDenied from 'components/shared/accessDenied/AccessDenied';
import LoadingComponent from 'components/shared/loadingcomponent/LoadingComponent';

//Hooks
import UseAdminPanel from './UseAdminPanel';

// @styles
import styles from './styles.module.scss';

// @utils
import { checkUserRole } from '../../utils/authUtils';
import formatDate from '../../utils/dateUtils';


const AdminPanel: React.FC = () => {
    //Zabezpieczenie strony
    const { loggedUser } = useContext(UserContext);
    const allowedRoles = ["admin"];
    const isAuthorized = checkUserRole(loggedUser, allowedRoles);
    //Hook
    const {
        //Ustawianie formData
        formData,
        handleInputChange,
        //Obsługa wysyłania
        handleSubmit,
        //Pobieranie listy użytkowników
        getUserList,
        userList,
        handleClickToChangeUserRole,
        showChangeUserRole,
        setShowChangeUserRole,
        userToChangeRole,
        roles,
        selectedRole,
        handleRoleChange,
        handleChangeUserRole
    } = UseAdminPanel();
    useEffect(() => {
        if (loggedUser) {
            getUserList();
        }
    }, [loggedUser]);
    if (!isAuthorized) {
        return (<AccessDenied />);
    } else if (isAuthorized && loggedUser) {
        return (
            <div className={styles.wrapper}>
                <form
                    className={styles.addUserFormBox}
                    onSubmit={handleSubmit}
                    method="POST"
                >
                    <div className={styles.formHeader}>
                        <h3>Dodaj użytkownika</h3>
                    </div>
                    <div className={styles.formContent}>
                        <label htmlFor="username">Nazwa użytkownika:</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            className={styles.btn} 
                            type="submit"
                            value="Dodaj"
                        />
                    </div>
                </form>
                {/* {showChangeUserRole && (
                    <div className={styles.changeUserRole}>
                        <h3>Zmień uprawnienia Użytkownika: {userToChangeRole.user.username}</h3>
                        <div className={styles.closePanel} onClick={() =>  setShowChangeUserRole(false) }>X</div>
                        <select value={selectedRole} onChange={handleRoleChange}>
                            <option value={0}>Wybierz</option>
                            {roles && roles.map((role, index) => 
                            <option key={index} value={role.id}>{role.name === "admin" ? 'Administrator' : role.name=== "manager" ? 'Kierownik' : role.name === "leader" ? 'Lider' : role.name === "employee" ? 'Pracownik' : null}</option>
                            )}
                        </select>
                        <button onClick={() =>  handleChangeUserRole() }>Zmień</button>
                    </div>
                )} */}
                <div className={styles.userList}>
                    <h4>Lista Użytkowników</h4>
                    <table className={styles.table}>
                        <thead className={styles.tHead}>
                            <tr>
                                <th className={styles.id}>Numer użytkownika</th>
                                <th>Login</th>
                                <th>Rola</th>
                                <th>Data dodania</th>
                            </tr>
                        </thead>
                        <tbody className={styles.tBody}>
                            {userList && userList.map(user => (
                                <tr
                                    key={user.id}
                                    className={styles.bodyTr}
                                    onClick={() =>  handleClickToChangeUserRole(user) }
                                >
                                    <td className={styles.id}>{user.user.id}</td>
                                    <td>{user.user.username}</td>
                                    <td>{user.roles[0].name === "admin" ? 'Administrator' : user.roles[0].name === "manager" ? 'Kierownik' : user.roles[0].name === "leader" ? 'Lider' : user.roles[0].name === "employee" ? 'Pracownik' : null}</td>
                                    <td>{user.creationDate === null ? 'BRAK' : formatDate(user.creationDate)}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            </div >
        );
    } else {
    return (<LoadingComponent />)
}
}
export default AdminPanel;