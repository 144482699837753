import axios from 'axios';
import { DeliveryContext } from 'contexts/Delivery';
import { UserContext } from "../../../contexts/LoggedUser";
import { useContext, useEffect, useState } from 'react';

export default function UseOrderManagement() {
    //Zmienne środowiskowe
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    //Pobieranie DataDelivery
    const { setAddComponentForm, setAddSubcomponentsForm } = useContext(DeliveryContext)
    const { loggedUser } = useContext(UserContext);
    const [deliveryData, setDeliveryData] = useState([])
    const [deliveryComponentType, setDeliveryComponentType] = useState()
    const deliveryId = window.location.pathname.split('/').pop();
    const getDeliveryData = () => {
        axios.get(`${API_BASE_URL}/deliveries/${deliveryId}`)
            .then((res) => {
                setDeliveryData([res.data]);
                setDeliveryComponentType(res.data.componentType.id)
                console.log(res)
            })
            .catch(() => {
                //DODAJ OBSŁUGE BŁĘDÓW
            })
    }


    // Dodawanie komponentu do zamówienia
    const [formDataComponentAdd, setFormDataComponentAdd] = useState({
        deliveryId: deliveryId,
        productionDate: '', // Domyślnie ustawiona na obecną datę w formacie 'YYYY-MM-DD'
        controlDate: '', // Domyślnie ustawiona na obecną datę w formacie 'YYYY-MM-DD'
        nameOne: '',
        nameTwo: '',
        oldMonNumber: '', // Domyślnie ustawione na 0
        newMonNumber: '', // Domyślnie ustawione na 0
        size: '' // Domyślnie ustawione na 0
    });
    const handleInputAddComponentChange = (e: any) => {
        const target = e.target;
        const name = target.name;
        console.log(formDataComponentAdd);
        setFormDataComponentAdd({
            ...formDataComponentAdd,
            [name]: target.value
        });
    }

    const [componentFromDelivery, setComponentsFromDelivery] = useState([]);
    const getComponentsFromDelivery = () => {
        axios.get(`${API_BASE_URL}/components/byDeliveryId/${deliveryId}`)
            .then((res) => {
                console.log(res);
                const sortedData = res.data.sort((a, b) => {
                    const lastSixA = a.insideNumber.slice(-6);
                    const lastSixB = b.insideNumber.slice(-6);
                    return lastSixB.localeCompare(lastSixA);
                }) // Sortowanie malejąco
                setComponentsFromDelivery(sortedData);
            })
            .catch(() => {
                //DODAJ OBSŁUGE BŁĘDÓW
            })
    }
    // Status subcomponentów
    const [subComponentsStatus, setSubcomponentsStatus] = useState([])
    const handleSubmitAddComponent = (e: any) => {
        console.log(formDataComponentAdd);
        e.preventDefault();
        console.log(formDataComponentAdd);
        axios
            .post(`${API_BASE_URL}/components`, {
                deliveryId: parseInt(deliveryId),
                productionDate: formDataComponentAdd.productionDate,
                controlDate: formDataComponentAdd.controlDate,
                nameOne: formDataComponentAdd.nameOne,
                nameTwo: formDataComponentAdd.nameTwo,
                oldMonNumber: parseInt(formDataComponentAdd.oldMonNumber),
                newMonNumber: parseInt(formDataComponentAdd.newMonNumber),
                size: parseFloat(formDataComponentAdd.size),
            })
            .then((res) => {
                console.log(res);
                setSubcomponentsStatus(res.data.componentSubcomponents)
                setAddComponentForm(false);
                setAddSubcomponentsForm(true);
                getComponentsFromDelivery();
                console.log(formDataComponentAdd);
                setFormDataComponentAdd({
                    deliveryId: deliveryId,
                    productionDate: '',
                    controlDate: '',
                    nameOne: '',
                    nameTwo: '',
                    oldMonNumber: '',
                    newMonNumber: '',
                    size: ''
                });
            })
            .catch(() => {
                //DODAJ OBSŁUGE BŁĘDÓW
            });
    }
    const [statusSubcomponentChange, setStatusSubcomponent] = useState({
        statusId: ''
    });

    const handleStatusSubcomponentChange = (e: any, id: any) => {
        const target = e;
        const name = 'statusId';
        console.log(id);
        console.log(target);
        console.log(statusSubcomponentChange);
        setStatusSubcomponent({
            ...statusSubcomponentChange,
            [name]: target
        })
        if (id && target) {
            axios
                .put(`${API_BASE_URL}/component-subcomponents/${id}`, {
                    statusId: parseInt(target, 10)
                }).then((res) => {
                    console.log(res)
                })
        }
    }

    useEffect(() => {
        if (loggedUser) {
            getDeliveryData();
            getComponentsFromDelivery();
            console.log(subComponentsStatus)
        }
    }, [loggedUser]);

    const [subcomponentConfig, setSubcomponentConfig] = useState([])

    const getSubcomponentConfig = () => {

		if (loggedUser && deliveryComponentType) {
			axios.get(`${API_BASE_URL}/subcomponents/config-by-component-type/${deliveryComponentType}`)
				.then((res) => {
					console.log(res.data);
                    setSubcomponentConfig(res.data)
				})
				.catch(error => {
					console.error('Error fetching data:', error);
				});
		}
	};

    useEffect(() => {
        if (loggedUser && deliveryComponentType) {
            getSubcomponentConfig();
        }
    }, [loggedUser, deliveryComponentType]);

    return {
        //Pobieranie listy komponentow z dostawy
        deliveryData,
        // Dodawanie komponentu do zamówienia
        formDataComponentAdd,
        handleInputAddComponentChange,
        handleSubmitAddComponent,
        componentFromDelivery,
        subComponentsStatus,
        handleStatusSubcomponentChange,
        subcomponentConfig
    };
}