import React, { useContext } from 'react';
import { DeliveryContext } from 'contexts/Delivery';

//@Styles
import styles from './styles.module.scss';

//@utils
import formatDate from '../../../utils/dateUtils';

//@components
import UseDeliveryListComponent from './UseDeliveryListComponent';


export default function DeliveryListComponent() {
    const { setDeliveryId } = useContext(DeliveryContext)

    const {
        //Filtrowanie
        reverseList,
        filters,
        setFilters,
        //Przekierowanie do widoku zarządzania dostawą
        redirectToDetailView
    } = UseDeliveryListComponent();
    console.log(reverseList);

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={styles.tHead}>
                    <tr>
                        <th>Numer dostawy <br />
                            <input
                                type="text"
                                value={filters.number}
                                onChange={e => setFilters({ ...filters, number: e.target.value })}
                                placeholder="Filtruj..."
                            />
                        </th>
                        <th>Klient <br />
                            <input
                                type="text"
                                value={filters.customerName}
                                onChange={e => setFilters({ ...filters, customerName: e.target.value })}
                                placeholder="Filtruj..."
                            />
                        </th>
                        <th>Status<br />
                            <input
                                type="text"
                                value={filters.statusName}
                                onChange={e => setFilters({ ...filters, statusName: e.target.value })}
                                placeholder="Filtruj..."
                            />
                        </th>
                        <th>Typ komponentu <br />
                            <input
                                type="text"
                                value={filters.componentTypeId}
                                onChange={e => setFilters({ ...filters, componentTypeId: e.target.value })}
                                placeholder="Filtruj..."
                            />
                        </th>
                        <th>Data dostawy
                            <br />
                            <input
                                type="text"
                                value={filters.deliveryDate}
                                onChange={e => setFilters({ ...filters, deliveryDate: e.target.value })}
                                placeholder="Filtruj..."
                            />
                        </th>
                        <th>Data zmiany
                            <br />
                            <input
                                type="text"
                                value={filters.lastModified}
                                onChange={e => setFilters({ ...filters, lastModified: e.target.value })}
                                placeholder="Filtruj..."
                            />
                        </th>
                        <th>Dodano przez
                            <br />
                            <input
                                type="text"
                                value={filters.createdByUserId}
                                onChange={e => setFilters({ ...filters, createdByUserId: e.target.value })}
                                placeholder="Filtruj..."
                            />
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.tBody}>
                    {reverseList && reverseList.map(list => (
                        <tr
                            key={list.id}
                            className={styles.bodyTr}
                            onClick={() => {
                                redirectToDetailView(list.id);
                                setDeliveryId(list.id);
                            }}
                        >
                            <td>{list.number}</td>
                            <td>{list.customer.name}</td>
                            <td className={list.status.name === "Zrealizowano" ? styles.realised : styles.toRealised}>{list.status.name}</td>
                            <td>{list.componentType.name}</td>
                            <td>{formatDate(list.deliveryDate)}</td>
                            <td>{formatDate(list.lastModified)}</td>
                            <td>{list.createdByUser.username}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}