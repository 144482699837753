import axios from "axios";
import { useEffect, useState } from "react";

// @types
import { TUser } from "../../types/shared/user";

export default function UseAdminPanel() {
    //Zmienne środowiskowe
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const STOCK_PASSWORD = process.env.REACT_APP_STOCK_PASSWORD_FOR_USER;
    //Ustawianie formData
    const [formData, setFormData] = useState({
        username: '',
        password: STOCK_PASSWORD,
    });
    const handleInputChange = (e: { target: any; }) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        // Jeśli zmieniane pole to username, ogranicz do liter
        if (name === "username") {
            // Zezwalaj tylko na litery
            const lettersOnly = value.replace(/[^a-zA-Z]/g, '');
            setFormData({
                ...formData,
                [name]: lettersOnly,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
        console.log(formData);
    };
    //Obsługa wysyłania
    const handleSubmit = (e: { preventDefault: () => void; }) => {
        console.log(formData);
        e.preventDefault();

        axios.post(`${API_BASE_URL}/users`, {
            username: formData.username,
            password: formData.password,
        })
            .then((res) => {
                getUserList();
                setFormData({
                    username: '',
                    password: STOCK_PASSWORD,
                });
            })
            .catch((error) => {
                //DODAJ OBSŁUGE BŁĘDÓW
            });
    }
    //Pobieranie listy użytkowników
    const [userList, setUserList] = useState<TUser[] | null>(null);
    const getUserList = () => {
        axios.get(`${API_BASE_URL}/users/users-roles`)
            .then((res) => {
                setUserList(res.data)
                console.log(res.data);
            })
            .catch(() => {
                //DODAJ OBSŁUGE BŁĘDÓW
            })
    }
    // Zmiana uprawnień użytkownika
    const [showChangeUserRole, setShowChangeUserRole] = useState(false);
    const [userToChangeRole, setUserToChangeRole] = useState(null);

    const handleClickToChangeUserRole = (user) => {
        // Aktualizacja dwóch stanów jednocześnie
        setUserToChangeRole(user);
        if (showChangeUserRole === false) {
            setShowChangeUserRole(!showChangeUserRole);
        } else if (user.user.id === userToChangeRole.user.id) {
            setShowChangeUserRole(!showChangeUserRole);
        }
    };

    const [roles, setRoles] = useState(null);

    const getRoles = () => {
        axios.get(`${API_BASE_URL}/roles`)
            .then((res) => {
                setRoles(res.data)
                console.log(res.data);
            })
            .catch(() => {
                //DODAJ OBSŁUGE BŁĘDÓW
            })
    }

    const [selectedRole, setSelectedRole] = useState<number>(0);

    const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        setSelectedRole(parseInt(selectedValue));
        console.log("Selected role ID:", selectedValue);
    };

    const handleChangeUserRole = () => {
        console.log(typeof (userToChangeRole.roles[0].id))
        console.log(typeof (userToChangeRole.user.id))

        axios.request({
            url: `${API_BASE_URL}/user-roles`,
            method: 'DELETE',
            data: {
                roleId: userToChangeRole.roles[0].id,
                userId: userToChangeRole.user.id,
            }
        })
            .then((res) => {

            })
            .catch((error) => {
                //DODAJ OBSŁUGE BŁĘDÓW
            });
    }

    useEffect(() => {
        getRoles();
    }, []);


    return {
        //Ustawianie formData
        formData,
        handleInputChange,
        //Obsługa wysyłania
        handleSubmit,
        //Pobieranie listy użytkowników
        getUserList,
        userList,
        handleClickToChangeUserRole,
        showChangeUserRole,
        setShowChangeUserRole,
        userToChangeRole,
        roles,
        selectedRole,
        handleRoleChange,
        handleChangeUserRole,
    };
}